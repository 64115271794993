/* eslint-disable dot-notation */
import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCreabots } from '@sections/creabots/features/creabotsSlice';
import { INITIATION_KIT_TUTORIAL_ID_TYPE } from '@services/rest/creabots/getProjectsProgress';
import { CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import { ProjectCard } from '../../projectsCard';
import { UserProjectCard } from '../../userProjectsCard';
import { useCardProject } from '@sections/creabots/features/hooks/useCardProject';
import { sortProjects } from '@sections/creabots/helpers/sortProjects';
import { TextBodyRegular } from '../../ui/typographies';
import { useIntl } from 'react-intl';

interface Props {
  projectSearch: string | null;
}

export const CardProjectView: React.FC<Props> = ({ projectSearch }) => {
  const isDesktop2XL = useMediaQuery('(min-width:1724px)');
  const isDesktopXL = useMediaQuery('(min-width:1330px)');

  const intl = useIntl();
  const { kits, loading, projects: userProjects } = useSelector(selectCreabots);
  const {
    isFirstStepProjectCompleted,
    projectsKits,
    getProjectURLByKit,
    getUserProjectURLByBoardId,
    projectProgress,
    searchedKitProjects,
    searchedUserProjects,
  } = useCardProject();

  const searchedProjects = kits.selected?.id
    ? searchedKitProjects(projectSearch)
    : searchedUserProjects(projectSearch).sort((a, b) =>
        sortProjects(a, b, kits.orderBy)
      );

  return (
    <Box
      sx={theme => ({
        display: searchedProjects.length === 0 ? 'flex' : 'grid',
        justifyContent: 'center',
        gridTemplateColumns: {
          xs: searchedProjects.length === 0 ? 'none' : 'repeat(2, 1fr)',
          lg: isDesktopXL ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',
          xl: isDesktop2XL ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)',
        },
        alignItems: 'center',
        gap: theme.spacing(3),
      })}
    >
      {kits.selected.id === 0 && userProjects.length === 0 ? (
        <Box display="flex" justifyContent="center">
          <TextBodyRegular itemType="info">
            {intl.formatMessage({
              id: 'creabots.dashboard.projectContainer.startToCreate',
            })}
          </TextBodyRegular>
        </Box>
      ) : (
        <>
          {kits.selected.id === 0 && searchedProjects.length === 0 ? (
            <Box display="flex" justifyContent="center">
              <TextBodyRegular itemType="info">
                {intl.formatMessage({
                  id: 'creabots.dashboard.projectContainer.withoutResults',
                })}
              </TextBodyRegular>
            </Box>
          ) : null}
        </>
      )}

      {kits.selected.id !== 0 && searchedProjects.length === 0 ? (
        <Box display="flex" justifyContent="center">
          <TextBodyRegular itemType="info">
            {intl.formatMessage({
              id: 'creabots.dashboard.projectContainer.withoutResults',
            })}
          </TextBodyRegular>
        </Box>
      ) : null}

      {!kits.selected?.id && !loading
        ? searchedProjects.map((project: any) => {
            return (
              <UserProjectCard
                key={crypto.randomUUID()}
                type="card"
                project={project}
                title={project.title}
                description={project.updatedAt}
                kitName={
                  projectsKits.find(
                    kit => kit.boardId === project.jsonBody?.board?.id
                  )?.name || ''
                }
                canvas={{
                  canvas: project.jsonBody.canvas,
                  canvasCss: project.canvasCss,
                  canvasBBoxX: project.jsonBody.canvasBBoxX,
                  canvasBBoxY: project.jsonBody.canvasBBoxY,
                  canvasBBoxWidth: project.jsonBody.canvasBBoxWidth,
                  canvasBBoxHeight: project.jsonBody.canvasBBoxHeight,
                }}
                redirectTo={getUserProjectURLByBoardId(
                  project.jsonBody?.board?.id || 1,
                  project.id
                )}
              />
            );
          })
        : null}
      {kits.selected?.id && searchedProjects.length
        ? searchedProjects.map(project => {
            const selectedKitId = kits.selected?.id;

            const canBlockCard =
              (project.id !== INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS &&
                !isFirstStepProjectCompleted) ||
              (!isFirstStepProjectCompleted &&
                selectedKitId !== CREABOTS_KIT_ID_TYPE.INITIATION);

            return (
              <ProjectCard
                key={crypto.randomUUID()}
                type="card"
                title={project.name}
                description={project.description}
                image={project.image}
                isBlocked={canBlockCard}
                isCustom={false}
                redirectTo={getProjectURLByKit(kits.selected.id, project.id)}
                progress={projectProgress(project.id, searchedProjects)}
              />
            );
          })
        : ''}
    </Box>
  );
};
