import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SidebarLabs } from '../components/sidebarLabs';
import { TopBarLabs } from '../components/dashboard/topbarLabs';
import {
  ListProjectView,
  CardProjectView,
} from '../components/dashboard/content/';
import { selectCreabots } from '../features/creabotsSlice';
import { useSelector } from 'react-redux';

type ViewFormat = 'card' | 'list';

const CreabotsKit = () => {
  const [projectSearch, setProjectSearch] = useState<string | null>(null);
  const [viewFormat, setViewFormat] = useState<ViewFormat>('card');
  const { kits } = useSelector(selectCreabots);

  const TOPBAR_FIXED_HEIGHT = kits.selected.id === 0 ? '246px' : '168px';

  const handleSearchProject = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProjectSearch(e.target.value);
  };

  const handleFormat = (
    _event: React.MouseEvent<HTMLElement>,
    format: string | null
  ) => {
    if (format !== null) {
      setViewFormat(format as ViewFormat);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        height: '100vh',
        width: '100%',
        bgcolor: 'surface.default',
        position: 'relative',
        zIndex: 9,
      }}
    >
      {/* Sidebar */}
      <SidebarLabs />

      <Box
        sx={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          marginLeft: '18.5rem',
        }}
      >
        {/* Topbar Fixed */}
        <TopBarLabs
          viewFormat={viewFormat}
          handleFormat={handleFormat}
          projectSearch={projectSearch}
          handleSearchProject={handleSearchProject}
        />

        {/* Content */}
        <Box
          sx={theme => ({
            overflowY: 'auto',
            marginTop: TOPBAR_FIXED_HEIGHT,
            height: `calc(100vh - ${TOPBAR_FIXED_HEIGHT})`,
            paddingTop: theme.spacing(2),
            marginRight: theme.spacing(3),
            paddingX:
              viewFormat === 'card' ? theme.spacing(3) : theme.spacing(1),
            bgcolor:
              viewFormat === 'card'
                ? 'surface.default'
                : theme.palette.common.white,
            paddingRight: '8px',
            borderStartStartRadius: '8px',
            borderStartEndRadius: '8px',
            paddingBottom: theme.spacing(3),
            // hide scrollbar
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none', // IE y Edge
            'scrollbar-width': 'none', // Firefox
          })}
        >
          {viewFormat === 'list' ? (
            <ListProjectView projectSearch={projectSearch} />
          ) : (
            <CardProjectView projectSearch={projectSearch} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreabotsKit;
